import React from 'react';

import Intro from 'components/Intro';
import PrivacyAccordion from 'components/InfoAccordion';
import { Footer, Head, Header } from 'landing/components';
import { Main } from 'landing/ui';
import CookiesPolicy from 'components/CookiesPolicy';

import data from './data.json';

export default function Cookie() {
  return (
    <>
      <Head {...data.head} />
      <Header isHeaderFixed {...data.header} />
      <Intro {...data.intro} page="internal" />
      <Main marginDisable>
        <PrivacyAccordion {...data.accordion} />
      </Main>
      <Footer />
      <CookiesPolicy />
    </>
  );
}
